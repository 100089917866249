import React, { createContext, useContext, useState, useCallback } from 'react';
import { Alert, Snackbar, AlertColor } from '@mui/material';

interface NotificationContextType {
  showNotification: (type: AlertColor, message: string, duration?: number) => void;
  closeNotification: () => void;
}

const NotificationContext = createContext<NotificationContextType>({
  showNotification: () => {},
  closeNotification: () => {}
});

export const useNotification = () => useContext(NotificationContext);

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<AlertColor>('info');
  const [duration, setDuration] = useState(6000);

  const showNotification = useCallback((
    newType: AlertColor,
    newMessage: string,
    newDuration: number = 6000
  ) => {
    setType(newType);
    setMessage(newMessage);
    setDuration(newDuration);
    setOpen(true);
  }, []);

  const closeNotification = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    closeNotification();
  };

  return (
    <NotificationContext.Provider value={{ showNotification, closeNotification }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          direction: 'rtl',
          '& .MuiAlert-root': {
            width: '100%',
            minWidth: '300px'
          }
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={type}
          sx={{
            width: '100%',
            '& .MuiAlert-message': {
              fontSize: '0.95rem',
              fontWeight: 500
            }
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;