import axios, { InternalAxiosRequestConfig } from 'axios';
import { 
  Customer, 
  Policy, 
  Claim, 
  Lead, 
  Document, 
  ApiResponse,
  DashboardStats
} from '../types/types';

// יצירת מופע axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

// Auth Interceptor
api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// מידע לדוגמה
const mockCustomers: Customer[] = [
  {
    _id: '1',
    firstName: 'יוסי',
    lastName: 'כהן',
    email: 'yossi@example.com',
    phone: '052-1234567',
    address: {
      street: 'רחוב השלום 10',
      city: 'תל אביב',
      zipCode: '6701101',
      country: 'ישראל'
    },
    createdAt: new Date('2023-01-15'),
    updatedAt: new Date('2023-05-20')
  },
  {
    _id: '2',
    firstName: 'רחל',
    lastName: 'לוי',
    email: 'rachel@example.com',
    phone: '054-7654321',
    address: {
      street: 'דרך מנחם בגין 30',
      city: 'ירושלים',
      zipCode: '9123403',
      country: 'ישראל'
    },
    createdAt: new Date('2023-02-10'),
    updatedAt: new Date('2023-06-15')
  },
  {
    _id: '3',
    firstName: 'משה',
    lastName: 'ישראלי',
    email: 'moshe@example.com',
    phone: '050-9876543',
    address: {
      street: 'שדרות הרצל 15',
      city: 'חיפה',
      zipCode: '3303340',
      country: 'ישראל'
    },
    createdAt: new Date('2023-03-05'),
    updatedAt: new Date('2023-07-10')
  }
];

const mockPolicies: Policy[] = [
  {
    _id: '1',
    policyNumber: 'POL-2023-001',
    type: 'car',
    customer: '1',
    startDate: new Date('2023-01-01'),
    endDate: new Date('2024-01-01'),
    payment: {
      amount: 4800,
      frequency: 'monthly',
      method: 'credit_card'
    },
    coverage: new Map([
      ['comprehensive', { limit: 2000000, deductible: 2000, description: 'ביטוח מקיף' }]
    ]),
    documents: [],
    createdAt: new Date('2023-01-01'),
    updatedAt: new Date('2023-01-01')
  },
  {
    _id: '2',
    policyNumber: 'POL-2023-002',
    type: 'health',
    customer: '2',
    startDate: new Date('2023-02-15'),
    endDate: new Date('2024-02-15'),
    payment: {
      amount: 12000,
      frequency: 'yearly',
      method: 'bank_transfer'
    },
    coverage: new Map([
      ['basic', { limit: 1000000, deductible: 1000, description: 'ביטוח בריאות בסיסי' }],
      ['surgery', { limit: 500000, deductible: 5000, description: 'כיסוי ניתוחים' }]
    ]),
    documents: [],
    createdAt: new Date('2023-02-15'),
    updatedAt: new Date('2023-02-15')
  },
  {
    _id: '3',
    policyNumber: 'POL-2023-003',
    type: 'property',
    customer: '3',
    startDate: new Date('2023-03-10'),
    endDate: new Date('2024-03-10'),
    payment: {
      amount: 2400,
      frequency: 'quarterly',
      method: 'credit_card'
    },
    coverage: new Map([
      ['structural', { limit: 1500000, deductible: 3000, description: 'ביטוח מבנה' }],
      ['contents', { limit: 500000, deductible: 1000, description: 'ביטוח תכולה' }]
    ]),
    documents: [],
    createdAt: new Date('2023-03-10'),
    updatedAt: new Date('2023-03-10')
  }
];

const mockClaims: Claim[] = [
  {
    _id: '1',
    claimNumber: 'CLM-2023-001',
    policy: '1',
    customer: '1',
    incidentDate: new Date('2023-05-10'),
    description: 'תאונת דרכים בכביש החוף',
    amount: {
      requested: 15000,
      approved: 12000
    },
    status: 'approved',
    documents: [],
    paymentDetails: {
      method: 'bank_transfer',
      paymentStatus: 'completed'
    },
    createdAt: new Date('2023-05-12'),
    updatedAt: new Date('2023-05-30')
  },
  {
    _id: '2',
    claimNumber: 'CLM-2023-002',
    policy: '2',
    customer: '2',
    incidentDate: new Date('2023-06-15'),
    description: 'טיפול רפואי דחוף',
    amount: {
      requested: 25000,
      approved: undefined
    },
    status: 'pending',
    documents: [],
    paymentDetails: {
      method: 'bank_transfer',
      paymentStatus: 'pending'
    },
    createdAt: new Date('2023-06-16'),
    updatedAt: new Date('2023-06-16')
  }
];

const mockLeads: Lead[] = [
  {
    _id: '1',
    firstName: 'דוד',
    lastName: 'גולדברג',
    email: 'david@example.com',
    phone: '053-1112222',
    source: 'אתר אינטרנט',
    status: 'new',
    notes: 'מעוניין בהצעת מחיר לביטוח רכב',
    createdAt: new Date('2023-07-01'),
    updatedAt: new Date('2023-07-01')
  },
  {
    _id: '2',
    firstName: 'שרה',
    lastName: 'אברהם',
    email: 'sarah@example.com',
    phone: '053-3334444',
    source: 'הפניה',
    status: 'contacted',
    notes: 'שוחחנו בטלפון, מעוניינת בביטוח דירה',
    createdAt: new Date('2023-07-05'),
    updatedAt: new Date('2023-07-10')
  },
  {
    _id: '3',
    firstName: 'יעקב',
    lastName: 'ברנר',
    email: 'yaakov@example.com',
    phone: '053-5556666',
    source: 'פייסבוק',
    status: 'qualified',
    notes: 'בעל 3 רכבים, מעוניין בהצעה לביטוח צי רכב',
    createdAt: new Date('2023-07-08'),
    updatedAt: new Date('2023-07-15')
  }
];

const mockDashboardStats: DashboardStats = {
  totalCustomers: 37,
  totalPolicies: 52,
  totalClaims: 18,
  totalLeads: 24,
  revenueStats: {
    monthly: 120000,
    quarterly: 350000,
    yearly: 1450000
  },
  claimStats: {
    pending: 5,
    approved: 8,
    rejected: 2,
    inReview: 3
  },
  leadStats: {
    new: 8,
    contacted: 7,
    qualified: 5,
    converted: 3,
    lost: 1
  }
};

// תשובות API מדומות
const mockResponses = {
  customers: mockCustomers,
  policies: mockPolicies,
  claims: mockClaims,
  leads: mockLeads,
  dashboardStats: mockDashboardStats
};

// פונקציות עזר למזג נתונים מדומים עם בקשות API
const wrapResponse = <T>(data: T): ApiResponse<T> => ({
  success: true,
  data
});

// Auth APIs
export const login = (username: string, password: string) => 
  Promise.resolve({ data: wrapResponse({ token: 'mock-token', user: { username, role: 'admin' } }) });

export const checkAuth = () => 
  Promise.resolve({ data: wrapResponse({ username: 'admin', role: 'admin' }) });

// Customers APIs
export const getCustomers = () => 
  Promise.resolve({ data: wrapResponse(mockResponses.customers) });

export const getCustomerById = (id: string) => {
  const customer = mockResponses.customers.find(c => c._id === id);
  return Promise.resolve({ data: wrapResponse(customer) });
};

export const createCustomer = (data: Partial<Customer>) => {
  const newCustomer = {
    ...data,
    _id: `${mockResponses.customers.length + 1}`,
    createdAt: new Date(),
    updatedAt: new Date()
  } as Customer;
  mockResponses.customers.push(newCustomer);
  return Promise.resolve({ data: wrapResponse(newCustomer) });
};

export const updateCustomer = (id: string, data: Partial<Customer>) => {
  const index = mockResponses.customers.findIndex(c => c._id === id);
  if (index !== -1) {
    mockResponses.customers[index] = {
      ...mockResponses.customers[index],
      ...data,
      updatedAt: new Date()
    };
    return Promise.resolve({ data: wrapResponse(mockResponses.customers[index]) });
  }
  return Promise.reject(new Error('Customer not found'));
};

export const deleteCustomer = (id: string) => {
  const index = mockResponses.customers.findIndex(c => c._id === id);
  if (index !== -1) {
    mockResponses.customers.splice(index, 1);
    return Promise.resolve({ data: wrapResponse(undefined) });
  }
  return Promise.reject(new Error('Customer not found'));
};

// Policies APIs
export const getPolicies = () => 
  Promise.resolve({ data: wrapResponse(mockResponses.policies) });

export const getPolicyById = (id: string) => {
  const policy = mockResponses.policies.find(p => p._id === id);
  return Promise.resolve({ data: wrapResponse(policy) });
};

export const createPolicy = (data: Partial<Policy>) => {
  const newPolicy = {
    ...data,
    _id: `${mockResponses.policies.length + 1}`,
    createdAt: new Date(),
    updatedAt: new Date()
  } as Policy;
  mockResponses.policies.push(newPolicy);
  return Promise.resolve({ data: wrapResponse(newPolicy) });
};

export const updatePolicy = (id: string, data: Partial<Policy>) => {
  const index = mockResponses.policies.findIndex(p => p._id === id);
  if (index !== -1) {
    mockResponses.policies[index] = {
      ...mockResponses.policies[index],
      ...data,
      updatedAt: new Date()
    };
    return Promise.resolve({ data: wrapResponse(mockResponses.policies[index]) });
  }
  return Promise.reject(new Error('Policy not found'));
};

export const deletePolicy = (id: string) => {
  const index = mockResponses.policies.findIndex(p => p._id === id);
  if (index !== -1) {
    mockResponses.policies.splice(index, 1);
    return Promise.resolve({ data: wrapResponse(undefined) });
  }
  return Promise.reject(new Error('Policy not found'));
};

// Claims APIs
export const getClaims = () => 
  Promise.resolve({ data: wrapResponse(mockResponses.claims) });

export const getClaimById = (id: string) => {
  const claim = mockResponses.claims.find(c => c._id === id);
  return Promise.resolve({ data: wrapResponse(claim) });
};

export const createClaim = (data: Partial<Claim>) => {
  const newClaim = {
    ...data,
    _id: `${mockResponses.claims.length + 1}`,
    createdAt: new Date(),
    updatedAt: new Date()
  } as Claim;
  mockResponses.claims.push(newClaim);
  return Promise.resolve({ data: wrapResponse(newClaim) });
};

export const updateClaim = (id: string, data: Partial<Claim>) => {
  const index = mockResponses.claims.findIndex(c => c._id === id);
  if (index !== -1) {
    mockResponses.claims[index] = {
      ...mockResponses.claims[index],
      ...data,
      updatedAt: new Date()
    };
    return Promise.resolve({ data: wrapResponse(mockResponses.claims[index]) });
  }
  return Promise.reject(new Error('Claim not found'));
};

export const deleteClaim = (id: string) => {
  const index = mockResponses.claims.findIndex(c => c._id === id);
  if (index !== -1) {
    mockResponses.claims.splice(index, 1);
    return Promise.resolve({ data: wrapResponse(undefined) });
  }
  return Promise.reject(new Error('Claim not found'));
};

// Leads APIs
export const getLeads = () => 
  Promise.resolve({ data: wrapResponse(mockResponses.leads) });

export const getLeadById = (id: string) => {
  const lead = mockResponses.leads.find(l => l._id === id);
  return Promise.resolve({ data: wrapResponse(lead) });
};

export const createLead = (data: Partial<Lead>) => {
  const newLead = {
    ...data,
    _id: `${mockResponses.leads.length + 1}`,
    createdAt: new Date(),
    updatedAt: new Date()
  } as Lead;
  mockResponses.leads.push(newLead);
  return Promise.resolve({ data: wrapResponse(newLead) });
};

export const updateLead = (id: string, data: Partial<Lead>) => {
  const index = mockResponses.leads.findIndex(l => l._id === id);
  if (index !== -1) {
    mockResponses.leads[index] = {
      ...mockResponses.leads[index],
      ...data,
      updatedAt: new Date()
    };
    return Promise.resolve({ data: wrapResponse(mockResponses.leads[index]) });
  }
  return Promise.reject(new Error('Lead not found'));
};

export const deleteLead = (id: string) => {
  const index = mockResponses.leads.findIndex(l => l._id === id);
  if (index !== -1) {
    mockResponses.leads.splice(index, 1);
    return Promise.resolve({ data: wrapResponse(undefined) });
  }
  return Promise.reject(new Error('Lead not found'));
};

// Dashboard Stats API
export const getDashboardStats = () => 
  Promise.resolve({ data: wrapResponse(mockResponses.dashboardStats) });

// File Upload
export const uploadFile = (file: File) => {
  return Promise.resolve({
    data: wrapResponse({
      url: URL.createObjectURL(file)
    })
  });
};

export default api;