import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  CircularProgress
} from '@mui/material';
import {
  Group as CustomersIcon,
  Description as PoliciesIcon,
  Assignment as ClaimsIcon,
  AttachMoney as RevenueIcon,
  Leaderboard as LeadsIcon
} from '@mui/icons-material';
import { useNotification } from '../context/NotificationContext';
import { DashboardStats, ApiResponse } from '../types/types';
import { formatCurrency } from '../utils/helpers';
import api from '../utils/api';

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats | null>(null);
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification();

  const fetchStats = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<ApiResponse<DashboardStats>>('/dashboard/stats');
      setStats(response.data.data);
    } catch (error) {
      showNotification('error', 'שגיאה בטעינת נתוני לוח הבקרה');
    } finally {
      setLoading(false);
    }
  }, [showNotification]);

  useEffect(() => {
    fetchStats();
    // עדכון כל 5 דקות
    const interval = setInterval(fetchStats, 300000);
    return () => clearInterval(interval);
  }, [fetchStats]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (!stats) {
    return (
      <Typography color="error" align="center">
        לא ניתן לטעון את הנתונים
      </Typography>
    );
  }

  const statCards = [
    {
      title: 'לקוחות',
      value: stats.totalCustomers,
      icon: <CustomersIcon sx={{ fontSize: 40 }} />,
      color: '#4caf50'
    },
    {
      title: 'פוליסות',
      value: stats.totalPolicies,
      icon: <PoliciesIcon sx={{ fontSize: 40 }} />,
      color: '#1976d2'
    },
    {
      title: 'תביעות',
      value: stats.totalClaims,
      icon: <ClaimsIcon sx={{ fontSize: 40 }} />,
      color: '#ff9800'
    },
    {
      title: 'לידים',
      value: stats.totalLeads,
      icon: <LeadsIcon sx={{ fontSize: 40 }} />,
      color: '#9c27b0'
    },
    {
      title: 'הכנסה חודשית',
      value: formatCurrency(stats.revenueStats.monthly),
      icon: <RevenueIcon sx={{ fontSize: 40 }} />,
      color: '#f44336'
    }
  ];

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        לוח בקרה
      </Typography>

      <Grid container spacing={3}>
        {statCards.map((stat, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent sx={{ textAlign: 'center' }}>
                <Box sx={{ color: stat.color, mb: 2 }}>
                  {stat.icon}
                </Box>
                <Typography variant="h5" component="div" gutterBottom>
                  {stat.value}
                </Typography>
                <Typography color="textSecondary">
                  {stat.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              סטטיסטיקות תביעות
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">ממתינות</Typography>
                <Typography variant="h6">{stats.claimStats.pending}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">מאושרות</Typography>
                <Typography variant="h6">{stats.claimStats.approved}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">נדחו</Typography>
                <Typography variant="h6">{stats.claimStats.rejected}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">בבדיקה</Typography>
                <Typography variant="h6">{stats.claimStats.inReview}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              סטטיסטיקות לידים
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2.4}>
                <Typography variant="body2" color="textSecondary">חדשים</Typography>
                <Typography variant="h6">{stats.leadStats.new}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <Typography variant="body2" color="textSecondary">נוצר קשר</Typography>
                <Typography variant="h6">{stats.leadStats.contacted}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <Typography variant="body2" color="textSecondary">מוכשרים</Typography>
                <Typography variant="h6">{stats.leadStats.qualified}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <Typography variant="body2" color="textSecondary">הומרו</Typography>
                <Typography variant="h6">{stats.leadStats.converted}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <Typography variant="body2" color="textSecondary">אבודים</Typography>
                <Typography variant="h6">{stats.leadStats.lost}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;