import { format } from 'date-fns';
import { he } from 'date-fns/locale';

// Date Formatting
export const formatDate = (date: Date | string | null, options: { includeTime?: boolean } = {}): string => {
  if (!date) return '';
  const d = new Date(date);
  const formatString = options.includeTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
  
  try {
    return format(d, formatString, { locale: he });
  } catch {
    return '';
  }
};

// Currency Formatting
export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('he-IL', {
    style: 'currency',
    currency: 'ILS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

// Phone Formatting
export const formatPhone = (phone: string): string => {
  if (!phone) return '';
  const cleaned = phone.replace(/\D/g, '');
  if (cleaned.length === 10) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }
  return phone;
};

// Email Validation
export const isValidEmail = (email: string): boolean => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
};

// Israeli ID Validation
export const isValidIsraeliID = (id: string): boolean => {
  if (!id || id.length !== 9 || isNaN(Number(id))) return false;
  
  const digits = Array.from(id).map(Number);
  const checkDigit = digits.pop()!;
  
  const sum = digits.reduce((acc, digit, i) => {
    const num = digit * ((i % 2) + 1);
    return acc + (num > 9 ? num - 9 : num);
  }, 0);
  
  return (10 - (sum % 10)) % 10 === checkDigit;
};

// File Size Formatting
export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

// Error Message Formatting
export const formatErrorMessage = (error: any): string => {
  if (typeof error === 'string') return error;
  if (error?.response?.data?.message) return error.response.data.message;
  if (error?.message) return error.message;
  return 'שגיאה לא ידועה';
};

// String Utils
export const truncate = (str: string, length: number = 50): string => {
  if (str.length <= length) return str;
  return `${str.slice(0, length)}...`;
};

// Number Utils
export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat('he-IL').format(num);
};

// Array Utils
export const sortByField = <T>(arr: T[], field: keyof T, ascending: boolean = true): T[] => {
  return [...arr].sort((a, b) => {
    if (a[field] < b[field]) return ascending ? -1 : 1;
    if (a[field] > b[field]) return ascending ? 1 : -1;
    return 0;
  });
};

// Object Utils
export const omitFields = <T extends object, K extends keyof T>(
  obj: T,
  fields: K[]
): Omit<T, K> => {
  const result = { ...obj };
  fields.forEach(field => delete result[field]);
  return result;
};

// Validation Utils
export const validateRequired = (value: any): boolean => {
  if (typeof value === 'string') return value.trim().length > 0;
  return value !== null && value !== undefined;
};

export const validateLength = (str: string, min: number, max: number): boolean => {
  return str.length >= min && str.length <= max;
};