import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  MenuItem,
  IconButton,
  Paper,
  Typography,
  Container
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useNotification } from '../context/NotificationContext';
import { DataTable } from '../components/DataTable';
import FileUpload from '../components/FileUpload';
import { Claim, Policy, TableColumn, ApiResponse, Document, PaymentMethod } from '../types/types';
import api from '../utils/api';
import { formatDate, formatCurrency } from '../utils/helpers';

const statusOptions = {
  pending: 'ממתין',
  approved: 'מאושר',
  rejected: 'נדחה',
  in_review: 'בבדיקה'
};

const paymentMethods: Record<PaymentMethod, string> = {
  bank_transfer: 'העברה בנקאית',
  check: "צ'ק",
  cash: 'מזומן',
  credit_card: 'כרטיס אשראי'
};

const initialClaim: Claim = {
  _id: '',
  claimNumber: '',
  policy: '',
  customer: '',
  incidentDate: new Date(),
  description: '',
  amount: {
    requested: 0,
    approved: undefined
  },
  status: 'pending',
  documents: [],
  paymentDetails: {
    method: 'bank_transfer',
    paymentStatus: 'pending'
  },
  createdAt: new Date(),
  updatedAt: new Date()
};

const Claims: React.FC = () => {
  const [claims, setClaims] = useState<Claim[]>([]);
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingClaim, setEditingClaim] = useState<Claim>(initialClaim);
  const { showNotification } = useNotification();

  const fetchClaims = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<ApiResponse<Claim[]>>('/claims');
      setClaims(response.data.data);
    } catch (error) {
      showNotification('error', 'שגיאה בטעינת תביעות');
    } finally {
      setLoading(false);
    }
  }, [showNotification]);

  const fetchPolicies = useCallback(async () => {
    try {
      const response = await api.get<ApiResponse<Policy[]>>('/policies');
      setPolicies(response.data.data);
    } catch (error) {
      showNotification('error', 'שגיאה בטעינת פוליסות');
    }
  }, [showNotification]);

  useEffect(() => {
    fetchClaims();
    fetchPolicies();
  }, [fetchClaims, fetchPolicies]);

  const validateClaim = (claim: Claim): boolean => {
    if (!claim.claimNumber.trim()) {
      showNotification('error', 'נא להזין מספר תביעה');
      return false;
    }
    if (!claim.description.trim()) {
      showNotification('error', 'נא להזין תיאור');
      return false;
    }
    if (claim.amount.requested <= 0) {
      showNotification('error', 'נא להזין סכום תביעה תקין');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateClaim(editingClaim)) return;

    try {
      if (editingClaim._id) {
        await api.put(`/claims/${editingClaim._id}`, editingClaim);
        showNotification('success', 'התביעה עודכנה בהצלחה');
      } else {
        await api.post('/claims', editingClaim);
        showNotification('success', 'התביעה נוצרה בהצלחה');
      }
      setOpenDialog(false);
      fetchClaims();
    } catch (error) {
      showNotification('error', 'שגיאה בשמירת תביעה');
    }
  };

  const handleEdit = (claim: Claim) => {
    setEditingClaim(claim);
    setOpenDialog(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק תביעה זו?')) {
      try {
        await api.delete(`/claims/${id}`);
        showNotification('success', 'התביעה נמחקה בהצלחה');
        fetchClaims();
      } catch (error) {
        showNotification('error', 'שגיאה במחיקת תביעה');
      }
    }
  };

  const handleFileUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await api.post<ApiResponse<{ url: string }>>('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      const newDocument: Document = {
        name: file.name,
        type: file.type,
        size: file.size,
        url: response.data.data.url,
        uploadedBy: 'current_user',
        createdAt: new Date()
      };

      setEditingClaim(prev => ({
        ...prev,
        documents: [...prev.documents, newDocument]
      }));
      
      showNotification('success', 'הקובץ הועלה בהצלחה');
    } catch (error) {
      showNotification('error', 'שגיאה בהעלאת קובץ');
    }
  };

  const columns: TableColumn<Claim>[] = [
    {
      id: 'claimNumber',
      label: 'מספר תביעה',
      sortable: true
    },
    {
      id: 'policy',
      label: 'פוליסה',
      format: (value) => {
        const policy = policies.find(p => p._id === value);
        return policy ? policy.policyNumber : value;
      },
      sortable: true
    },
    {
      id: 'incidentDate',
      label: 'תאריך אירוע',
      format: (value) => formatDate(value),
      sortable: true
    },
    {
      id: 'amount',
      label: 'סכום נדרש',
      format: (value) => formatCurrency(value.requested),
      sortable: true
    },
    {
      id: 'status',
      label: 'סטטוס',
      format: (value) => statusOptions[value as keyof typeof statusOptions],
      sortable: true
    },
    {
      id: 'actions',
      label: 'פעולות',
      format: (_, row) => (
        <Box>
          <IconButton color="primary" size="small" onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" size="small" onClick={() => handleDelete(row._id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  return (
    <Container maxWidth="xl">
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5">ניהול תביעות</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setEditingClaim(initialClaim);
              setOpenDialog(true);
            }}
          >
            תביעה חדשה
          </Button>
        </Box>

        <Paper>
          <DataTable<Claim>
            columns={columns}
            data={claims}
            loading={loading}
          />
        </Paper>
      </Box>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingClaim._id ? 'עריכת תביעה' : 'תביעה חדשה'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="מספר תביעה"
                value={editingClaim.claimNumber}
                onChange={(e) => setEditingClaim(prev => ({
                  ...prev,
                  claimNumber: e.target.value
                }))}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="פוליסה"
                value={editingClaim.policy}
                onChange={(e) => setEditingClaim(prev => ({
                  ...prev,
                  policy: e.target.value
                }))}
                required
              >
                {policies.map((policy) => (
                  <MenuItem key={policy._id} value={policy._id}>
                    {policy.policyNumber}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                label="תאריך אירוע"
                value={editingClaim.incidentDate}
                onChange={(date) => setEditingClaim(prev => ({
                  ...prev,
                  incidentDate: date || new Date()
                }))}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: true
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="סכום נדרש"
                type="number"
                value={editingClaim.amount.requested}
                onChange={(e) => setEditingClaim(prev => ({
                  ...prev,
                  amount: {
                    ...prev.amount,
                    requested: Number(e.target.value)
                  }
                }))}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="תיאור האירוע"
                value={editingClaim.description}
                onChange={(e) => setEditingClaim(prev => ({
                  ...prev,
                  description: e.target.value
                }))}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="סטטוס"
                value={editingClaim.status}
                onChange={(e) => setEditingClaim(prev => ({
                  ...prev,
                  status: e.target.value as Claim['status']
                }))}
                required
              >
                {Object.entries(statusOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="אמצעי תשלום"
                value={editingClaim.paymentDetails.method}
                onChange={(e) => setEditingClaim(prev => ({
                  ...prev,
                  paymentDetails: {
                    ...prev.paymentDetails,
                    method: e.target.value as PaymentMethod
                  }
                }))}
              >
                {Object.entries(paymentMethods).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FileUpload onUpload={handleFileUpload} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>ביטול</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingClaim._id ? 'עדכן' : 'צור'} תביעה
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Claims;