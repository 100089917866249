import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  MenuItem,
  IconButton,
  Paper,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNotification } from '../context/NotificationContext';
import { DataTable } from '../components/DataTable';
import { Lead, TableColumn } from '../types/types';
import api from '../utils/api';
import { formatDate } from '../utils/helpers';

const initialLead: Lead = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  source: '',
  status: 'new',
  notes: '',
  createdAt: new Date(),
  updatedAt: new Date()
};

const statusOptions = {
  new: 'חדש',
  contacted: 'נוצר קשר',
  qualified: 'מוכשר',
  converted: 'הומר',
  lost: 'אבוד'
};

const Leads: React.FC = () => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingLead, setEditingLead] = useState<Lead>(initialLead);
  const { showNotification } = useNotification();

  const fetchLeads = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get<Lead[]>('/leads');
      setLeads(data);
    } catch (error) {
      showNotification('error', 'שגיאה בטעינת לידים');
    } finally {
      setLoading(false);
    }
  }, [showNotification]);

  useEffect(() => {
    fetchLeads();
  }, [fetchLeads]);

  const columns: TableColumn<Lead>[] = [
    {
      id: 'firstName',
      label: 'שם פרטי',
      sortable: true
    },
    {
      id: 'lastName',
      label: 'שם משפחה',
      sortable: true
    },
    {
      id: 'email',
      label: 'דואר אלקטרוני',
      sortable: true
    },
    {
      id: 'phone',
      label: 'טלפון'
    },
    {
      id: 'source',
      label: 'מקור'
    },
    {
      id: 'status',
      label: 'סטטוס',
      format: (value) => statusOptions[value as keyof typeof statusOptions],
      sortable: true
    },
    {
      id: 'actions',
      label: 'פעולות',
      format: (_, row) => (
        <Box>
          <IconButton size="small" onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => handleDelete(row._id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  const handleEdit = (lead: Lead) => {
    setEditingLead(lead);
    setOpenDialog(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/leads/${id}`);
      showNotification('success', 'הליד נמחק בהצלחה');
      fetchLeads();
    } catch (error) {
      showNotification('error', 'שגיאה במחיקת ליד');
    }
  };

  const handleSubmit = async () => {
    try {
      if (editingLead._id) {
        await api.put(`/leads/${editingLead._id}`, editingLead);
        showNotification('success', 'הליד עודכן בהצלחה');
      } else {
        await api.post('/leads', editingLead);
        showNotification('success', 'הליד נוצר בהצלחה');
      }
      setOpenDialog(false);
      fetchLeads();
    } catch (error) {
      showNotification('error', 'שגיאה בשמירת ליד');
    }
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhone = (phone: string): boolean => {
    const re = /^[0-9-+]{9,}$/;
    return re.test(phone);
  };

  const validateLead = (): boolean => {
    if (!editingLead.firstName.trim() || !editingLead.lastName.trim()) {
      showNotification('error', 'יש להזין שם פרטי ושם משפחה');
      return false;
    }
    if (!validateEmail(editingLead.email)) {
      showNotification('error', 'כתובת דואר אלקטרוני לא תקינה');
      return false;
    }
    if (!validatePhone(editingLead.phone)) {
      showNotification('error', 'מספר טלפון לא תקין');
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setEditingLead(initialLead);
            setOpenDialog(true);
          }}
        >
          ליד חדש
        </Button>
      </Box>

      <Paper>
        <DataTable<Lead>
          columns={columns}
          data={leads}
          loading={loading}
        />
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingLead._id ? 'עריכת ליד' : 'ליד חדש'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="שם פרטי"
                value={editingLead.firstName}
                onChange={(e) => setEditingLead(prev => ({
                  ...prev,
                  firstName: e.target.value
                }))}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="שם משפחה"
                value={editingLead.lastName}
                onChange={(e) => setEditingLead(prev => ({
                  ...prev,
                  lastName: e.target.value
                }))}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="דואר אלקטרוני"
                type="email"
                value={editingLead.email}
                onChange={(e) => setEditingLead(prev => ({
                  ...prev,
                  email: e.target.value
                }))}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="טלפון"
                value={editingLead.phone}
                onChange={(e) => setEditingLead(prev => ({
                  ...prev,
                  phone: e.target.value
                }))}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="מקור"
                value={editingLead.source}
                onChange={(e) => setEditingLead(prev => ({
                  ...prev,
                  source: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="סטטוס"
                value={editingLead.status}
                onChange={(e) => setEditingLead(prev => ({
                  ...prev,
                  status: e.target.value as Lead['status']
                }))}
                required
              >
                {Object.entries(statusOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="הערות"
                value={editingLead.notes}
                onChange={(e) => setEditingLead(prev => ({
                  ...prev,
                  notes: e.target.value
                }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>ביטול</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingLead._id ? 'עדכן' : 'צור'} ליד
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Leads;