import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  LinearProgress
} from '@mui/material';
import { CloudUpload as UploadIcon } from '@mui/icons-material';
import { useNotification } from '../context/NotificationContext';
import { formatFileSize } from '../utils/helpers';

interface FileUploadProps {
  onUpload: (file: File) => Promise<void>;
  accept?: string;
  maxSize?: number;
  multiple?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onUpload,
  accept = '.pdf,.doc,.docx,image/*',
  maxSize = 5242880, // 5MB default
  multiple = false
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { showNotification } = useNotification();

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    const fileList = Array.from(files);
    
    // בדיקת גודל קבצים
    const oversizedFiles = fileList.filter(file => file.size > maxSize);
    if (oversizedFiles.length > 0) {
      showNotification('error', `קובץ גדול מדי. הגודל המקסימלי המותר הוא ${formatFileSize(maxSize)}`);
      return;
    }

    setUploading(true);
    setProgress(0);

    try {
      for (const file of fileList) {
        // סימולציה של התקדמות העלאה
        for (let i = 0; i <= 100; i += 10) {
          setProgress(i);
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        
        await onUpload(file);
      }
      showNotification('success', 'הקובץ הועלה בהצלחה');
    } catch (error) {
      showNotification('error', 'שגיאה בהעלאת קובץ');
    } finally {
      setUploading(false);
      setProgress(0);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <Box>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
        accept={accept}
        multiple={multiple}
        style={{ display: 'none' }}
      />
      <Button
        variant="outlined"
        color="primary"
        startIcon={uploading ? <CircularProgress size={20} /> : <UploadIcon />}
        onClick={handleClick}
        disabled={uploading}
        fullWidth
      >
        {uploading ? 'מעלה...' : 'העלה קובץ'}
      </Button>
      <Typography variant="caption" color="textSecondary" display="block" sx={{ mt: 1 }}>
        גודל מקסימלי: {formatFileSize(maxSize)}
      </Typography>
      {uploading && (
        <Box sx={{ mt: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;