import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  Divider,
  Paper,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Wheel from '@uiw/react-color-wheel';
import { ColorResult } from '@uiw/color-convert';
import { useNotification } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';
import { Settings } from '../types/types';
import api from '../utils/api';

const defaultSettings: Settings = {
  _id: '',
  companyName: '',
  email: '',
  phone: '',
  address: '',
  primaryColor: '#1976d2',
  darkMode: false,
  emailNotifications: true,
  smsNotifications: false,
  language: 'he',
  createdAt: new Date(),
  updatedAt: new Date()
};

const SettingsPage: React.FC = () => {
  const theme = useTheme();
  const { showNotification } = useNotification();
  const { user } = useAuth();
  const [settings, setSettings] = useState<Settings>(defaultSettings);
  const [loading, setLoading] = useState(false);
  const [hex, setHex] = useState(defaultSettings.primaryColor);

  const fetchSettings = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get<Settings>('/settings');
      setSettings(data);
      setHex(data.primaryColor);
    } catch (error) {
      showNotification('error', 'שגיאה בטעינת ההגדרות');
    } finally {
      setLoading(false);
    }
  }, [showNotification]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const handleColorChange = (color: ColorResult) => {
    setHex(color.hex);
    setSettings(prev => ({
      ...prev,
      primaryColor: color.hex
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validateSettings = (): boolean => {
    if (!settings.companyName.trim()) {
      showNotification('error', 'נא להזין שם חברה');
      return false;
    }
    if (!settings.email.trim()) {
      showNotification('error', 'נא להזין כתובת דואר אלקטרוני');
      return false;
    }
    if (!settings.phone.trim()) {
      showNotification('error', 'נא להזין מספר טלפון');
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    if (!validateSettings()) return;

    try {
      await api.put('/settings', settings);
      showNotification('success', 'ההגדרות נשמרו בהצלחה');
    } catch (error) {
      showNotification('error', 'שגיאה בשמירת ההגדרות');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <Typography>טוען...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper>
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                הגדרות מערכת
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="שם החברה"
                    name="companyName"
                    value={settings.companyName}
                    onChange={handleChange}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="דואר אלקטרוני"
                    name="email"
                    value={settings.email}
                    onChange={handleChange}
                    margin="normal"
                    type="email"
                    required
                  />
                  <TextField
                    fullWidth
                    label="טלפון"
                    name="phone"
                    value={settings.phone}
                    onChange={handleChange}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="כתובת"
                    name="address"
                    value={settings.address}
                    onChange={handleChange}
                    margin="normal"
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    צבע ראשי
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    <Wheel
                      color={hex}
                      onChange={handleColorChange}
                      style={{ margin: '0 auto' }}
                    />
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.darkMode}
                        onChange={handleChange}
                        name="darkMode"
                      />
                    }
                    label="מצב כהה"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.emailNotifications}
                        onChange={handleChange}
                        name="emailNotifications"
                      />
                    }
                    label="התראות במייל"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.smsNotifications}
                        onChange={handleChange}
                        name="smsNotifications"
                      />
                    }
                    label="התראות SMS"
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  שמור הגדרות
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      </Paper>
    </Box>
  );
};

export default SettingsPage;