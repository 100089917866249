import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  MenuItem,
  IconButton,
  Paper,
  Typography
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNotification } from '../context/NotificationContext';
import { DataTable } from '../components/DataTable';
import FileUpload from '../components/FileUpload';
import { Policy, PolicyType, PaymentFrequency, PaymentMethod, TableColumn, ApiResponse } from '../types/types';
import api from '../utils/api';
import { formatDate, formatCurrency } from '../utils/helpers';

const policyTypes: Record<PolicyType, string> = {
  car: 'רכב',
  life: 'חיים',
  health: 'בריאות',
  property: 'רכוש',
  business: 'עסקים'
};

const paymentFrequencies: Record<PaymentFrequency, string> = {
  monthly: 'חודשי',
  quarterly: 'רבעוני',
  yearly: 'שנתי',
  one_time: 'חד פעמי'
};

const paymentMethods: Record<PaymentMethod, string> = {
  credit_card: 'כרטיס אשראי',
  bank_transfer: 'העברה בנקאית',
  check: "צ'ק",
  cash: 'מזומן'
};

const initialPolicy: Policy = {
  _id: '',
  policyNumber: '',
  type: 'car',
  customer: '',
  startDate: new Date(),
  endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  payment: {
    amount: 0,
    frequency: 'monthly',
    method: 'credit_card'
  },
  coverage: new Map([
    ['comprehensive', { limit: 2000000, deductible: 2000, description: 'מקיף' }]
  ]),
  documents: [],
  createdAt: new Date(),
  updatedAt: new Date()
};

const Policies: React.FC = () => {
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingPolicy, setEditingPolicy] = useState<Policy>(initialPolicy);
  const { showNotification } = useNotification();

  const fetchPolicies = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<ApiResponse<Policy[]>>('/policies');
      setPolicies(response.data.data);
    } catch (error) {
      showNotification('error', 'שגיאה בטעינת פוליסות');
    } finally {
      setLoading(false);
    }
  }, [showNotification]);

  useEffect(() => {
    fetchPolicies();
  }, [fetchPolicies]);

  const handleEdit = useCallback((policy: Policy) => {
    setEditingPolicy(policy);
    setOpenDialog(true);
  }, []);

  const handleDelete = async (id: string) => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק פוליסה זו?')) {
      try {
        await api.delete(`/policies/${id}`);
        showNotification('success', 'הפוליסה נמחקה בהצלחה');
        fetchPolicies();
      } catch (error) {
        showNotification('error', 'שגיאה במחיקת פוליסה');
      }
    }
  };

  const validatePolicy = (policy: Policy): boolean => {
    if (!policy.policyNumber.trim()) {
      showNotification('error', 'נא להזין מספר פוליסה');
      return false;
    }
    if (!policy.startDate || !policy.endDate) {
      showNotification('error', 'נא להזין תאריכי התחלה וסיום');
      return false;
    }
    if (new Date(policy.startDate) >= new Date(policy.endDate)) {
      showNotification('error', 'תאריך התחלה חייב להיות לפני תאריך סיום');
      return false;
    }
    if (!policy.payment.amount || policy.payment.amount <= 0) {
      showNotification('error', 'נא להזין סכום תשלום תקין');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validatePolicy(editingPolicy)) return;

    try {
      if (editingPolicy._id) {
        await api.put(`/policies/${editingPolicy._id}`, editingPolicy);
        showNotification('success', 'הפוליסה עודכנה בהצלחה');
      } else {
        await api.post('/policies', editingPolicy);
        showNotification('success', 'הפוליסה נוצרה בהצלחה');
      }
      setOpenDialog(false);
      fetchPolicies();
    } catch (error) {
      showNotification('error', 'שגיאה בשמירת פוליסה');
    }
  };

  const columns: TableColumn<Policy>[] = [
    {
      id: 'policyNumber',
      label: 'מספר פוליסה',
      sortable: true
    },
    {
      id: 'type',
      label: 'סוג',
      format: (value) => policyTypes[value as PolicyType],
      sortable: true
    },
    {
      id: 'startDate',
      label: 'תאריך התחלה',
      format: (value) => formatDate(value),
      sortable: true
    },
    {
      id: 'endDate',
      label: 'תאריך סיום',
      format: (value) => formatDate(value),
      sortable: true
    },
    {
      id: 'payment',
      label: 'תשלום',
      format: (value) => formatCurrency(value.amount)
    },
    {
      id: 'actions',
      label: 'פעולות',
      format: (_, row) => (
        <Box>
          <IconButton color="primary" size="small" onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" size="small" onClick={() => handleDelete(row._id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">פוליסות</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setEditingPolicy(initialPolicy);
            setOpenDialog(true);
          }}
        >
          פוליסה חדשה
        </Button>
      </Box>

      <Paper>
        <DataTable<Policy>
          columns={columns}
          data={policies}
          loading={loading}
        />
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingPolicy._id ? 'עריכת פוליסה' : 'פוליסה חדשה'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="מספר פוליסה"
                value={editingPolicy.policyNumber}
                onChange={(e) => setEditingPolicy(prev => ({
                  ...prev,
                  policyNumber: e.target.value
                }))}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="סוג פוליסה"
                value={editingPolicy.type}
                onChange={(e) => setEditingPolicy(prev => ({
                  ...prev,
                  type: e.target.value as PolicyType
                }))}
                required
              >
                {Object.entries(policyTypes).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="תאריך התחלה"
                value={editingPolicy.startDate}
                onChange={(date) => setEditingPolicy(prev => ({
                  ...prev,
                  startDate: date || new Date()
                }))}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: true
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="תאריך סיום"
                value={editingPolicy.endDate}
                onChange={(date) => setEditingPolicy(prev => ({
                  ...prev,
                  endDate: date || new Date()
                }))}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: true
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="סכום"
                type="number"
                value={editingPolicy.payment.amount}
                onChange={(e) => setEditingPolicy(prev => ({
                  ...prev,
                  payment: {
                    ...prev.payment,
                    amount: Number(e.target.value)
                  }
                }))}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="תדירות תשלום"
                value={editingPolicy.payment.frequency}
                onChange={(e) => setEditingPolicy(prev => ({
                  ...prev,
                  payment: {
                    ...prev.payment,
                    frequency: e.target.value as PaymentFrequency
                  }
                }))}
              >
                {Object.entries(paymentFrequencies).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="אמצעי תשלום"
                value={editingPolicy.payment.method}
                onChange={(e) => setEditingPolicy(prev => ({
                  ...prev,
                  payment: {
                    ...prev.payment,
                    method: e.target.value as PaymentMethod
                  }
                }))}
              >
                {Object.entries(paymentMethods).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>ביטול</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingPolicy._id ? 'עדכן' : 'צור'} פוליסה
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Policies;