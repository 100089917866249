import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Home as HomeIcon } from '@mui/icons-material';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        p: 3,
        bgcolor: 'background.default'
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: '6rem',
          color: 'primary.main',
          mb: 2,
          fontWeight: 'bold'
        }}
      >
        404
      </Typography>
      <Typography variant="h4" gutterBottom>
        הדף לא נמצא
      </Typography>
      <Typography
        color="text.secondary"
        sx={{ mb: 4, maxWidth: 500 }}
      >
        מצטערים, אך הדף שחיפשת אינו קיים. ייתכן שהוא הוסר או שהכתובת שגויה.
      </Typography>
      <Button
        variant="contained"
        startIcon={<HomeIcon />}
        onClick={() => navigate('/dashboard')}
        size="large"
      >
        חזרה לדף הבית
      </Button>
    </Box>
  );
};

export default NotFound;