import React, { createContext, useContext, useState, useCallback } from 'react';
import { useNotification } from './NotificationContext';

export interface User {
  _id: string;
  username: string;
  email?: string;
  firstName: string;
  lastName: string;
  role: 'admin' | 'user';
}

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  loading: false,
  login: async () => {},
  logout: () => {}
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();

  const login = useCallback(async (username: string, password: string) => {
    // בשלב זה, נאפשר התחברות עם המשתמש הדיפולטיבי
    setLoading(true);
    try {
      if (username === 'admin' && password === 'admin123') {
        setUser({
          _id: '1',
          username: 'admin',
          firstName: 'מנהל',
          lastName: 'מערכת',
          role: 'admin'
        });
        showNotification('success', 'התחברת בהצלחה');
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      showNotification('error', 'שם משתמש או סיסמה שגויים');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [showNotification]);

  const logout = useCallback(() => {
    setUser(null);
    showNotification('info', 'התנתקת מהמערכת');
  }, [showNotification]);

  return (
    <AuthContext.Provider value={{
      user,
      isAuthenticated: !!user,
      loading,
      login,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;