import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNotification } from '../context/NotificationContext';
import { DataTable } from '../components/DataTable';
import { Customer, TableColumn } from '../types/types';
import { getCustomers, createCustomer, updateCustomer, deleteCustomer } from '../utils/api';
import { formatDate, isValidEmail, formatPhone } from '../utils/helpers';

interface CustomerAddress {
  street: string;
  city: string;
  zipCode: string;
  country: string;
}

const initialAddress: CustomerAddress = {
  street: '',
  city: '',
  zipCode: '',
  country: 'ישראל'
};

const initialCustomer: Customer = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: initialAddress,
  createdAt: new Date(),
  updatedAt: new Date()
};

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState<Customer>(initialCustomer);
  const { showNotification } = useNotification();

  const fetchCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getCustomers();
      setCustomers(response.data.data);
    } catch (error) {
      showNotification('error', 'שגיאה בטעינת לקוחות');
    } finally {
      setLoading(false);
    }
  }, [showNotification]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const columns: TableColumn<Customer>[] = [
    {
      id: 'name',
      label: 'שם מלא',
      format: (_, customer) => `${customer.firstName} ${customer.lastName}`,
      sortable: true
    },
    {
      id: 'email',
      label: 'דואר אלקטרוני',
      sortable: true
    },
    {
      id: 'phone',
      label: 'טלפון',
      format: (value) => formatPhone(value),
      sortable: true
    },
    {
      id: 'address',
      label: 'כתובת',
      format: (value) => `${value.street}, ${value.city}`,
      sortable: true
    },
    {
      id: 'createdAt',
      label: 'תאריך הצטרפות',
      format: (value) => formatDate(value),
      sortable: true
    },
    {
      id: 'actions',
      label: 'פעולות',
      width: '120px',
      format: (_, row) => (
        <Box>
          <IconButton size="small" onClick={() => handleEdit(row)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => handleDelete(row._id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  const handleEdit = useCallback((customer: Customer) => {
    setEditingCustomer({
      ...customer,
      address: {
        ...initialAddress,
        ...customer.address
      }
    });
    setOpenDialog(true);
  }, []);

  const handleDelete = async (id: string) => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק לקוח זה?')) {
      try {
        await deleteCustomer(id);
        showNotification('success', 'הלקוח נמחק בהצלחה');
        fetchCustomers();
      } catch (error) {
        showNotification('error', 'שגיאה במחיקת לקוח');
      }
    }
  };

  const validateCustomer = (customer: Customer): boolean => {
    if (!customer.firstName.trim() || !customer.lastName.trim()) {
      showNotification('error', 'נא למלא שם פרטי ושם משפחה');
      return false;
    }
    if (!isValidEmail(customer.email)) {
      showNotification('error', 'כתובת דואר אלקטרוני לא תקינה');
      return false;
    }
    if (!customer.phone.trim()) {
      showNotification('error', 'נא למלא מספר טלפון');
      return false;
    }
    if (!customer.address.street.trim() || !customer.address.city.trim()) {
      showNotification('error', 'נא למלא כתובת מלאה');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateCustomer(editingCustomer)) return;

    try {
      if (editingCustomer._id) {
        await updateCustomer(editingCustomer._id, editingCustomer);
        showNotification('success', 'הלקוח עודכן בהצלחה');
      } else {
        await createCustomer(editingCustomer);
        showNotification('success', 'הלקוח נוצר בהצלחה');
      }
      setOpenDialog(false);
      fetchCustomers();
    } catch (error) {
      showNotification('error', 'שגיאה בשמירת לקוח');
    }
  };

  const handleInputChange = (field: keyof Omit<Customer, 'address' | '_id' | 'createdAt' | 'updatedAt'>, value: string) => {
    setEditingCustomer(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAddressChange = (field: keyof CustomerAddress, value: string) => {
    setEditingCustomer(prev => ({
      ...prev,
      address: {
        ...prev.address,
        [field]: value
      }
    }));
  };

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">לקוחות</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setEditingCustomer(initialCustomer);
            setOpenDialog(true);
          }}
        >
          לקוח חדש
        </Button>
      </Box>

      <Paper>
        <DataTable<Customer>
          columns={columns}
          data={customers}
          loading={loading}
        />
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingCustomer._id ? 'עריכת לקוח' : 'לקוח חדש'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="שם פרטי"
                value={editingCustomer.firstName}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
                required
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="שם משפחה"
                value={editingCustomer.lastName}
                onChange={(e) => handleInputChange('lastName', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="דואר אלקטרוני"
                type="email"
                value={editingCustomer.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="טלפון"
                value={editingCustomer.phone}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="רחוב"
                value={editingCustomer.address.street}
                onChange={(e) => handleAddressChange('street', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="עיר"
                value={editingCustomer.address.city}
                onChange={(e) => handleAddressChange('city', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="מיקוד"
                value={editingCustomer.address.zipCode}
                onChange={(e) => handleAddressChange('zipCode', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="מדינה"
                value={editingCustomer.address.country}
                onChange={(e) => handleAddressChange('country', e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>ביטול</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingCustomer._id ? 'עדכן' : 'צור'} לקוח
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Customers;